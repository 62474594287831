<template>
  <tp-text-field
    v-model="price"
    :append-icon="appendIcon"
    :append-outer-icon="appendOuterIcon"
    :background-color="backgroundColor"
    :class="customClass"
    :clearable="clearable"
    :dense="dense"
    :disabled="disabled"
    :error="error"
    :error-messages="errorMessage"
    :flat="flat"
    :hide-details="hideDetails"
    :label="label"
    :outlined="outlined"
    :placeholder="placeholder"
    :prepend-icon="prependIcon"
    :prepend-inner-icon="prependInnerIcon"
    :readonly="readonly"
    :solo="solo"
    :style="customStyle"
    :suffix="suffix"
    :validate="validate"
    v-bind="$attrs"
    @keypress="keyPress"
  ></tp-text-field>
</template>

<script>
export default {
  props: {
    appendIcon: {
      type: String
    },
    appendOuterIcon: {
      type: String
    },
    backgroundColor: {
      type: String
    },
    clearable: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: [String, Object]
    },
    customStyle: {
      type: [String, Object]
    },
    dense: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    emptyValue: {
      type: [Number, String],
      default: 0
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: Array
    },
    filled: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: [Boolean, String]
    },
    label: {
      type: String
    },
    maxValue: {
      type: Number
    },
    minValue: {
      type: Number
    },
    negative: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String
    },
    prependIcon: {
      type: String
    },
    prependInnerIcon: {
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    solo: {
      type: Boolean,
      default: false
    },
    suffix: {
      type: String,
      default: "₫"
    },
    value: {
      type: [Number, String]
    },
    validate: {
      type: [String, Object],
      default: ""
    }
  },
  data() {
    return {
      minMaxRules: [
        value =>
          !(Number(this.cleanNumber(value)) > this.maxValue) ||
          "Đã quá mức giá tối đa!",
        value =>
          !(Number(this.cleanNumber(value)) < this.minValue) ||
          "Chưa đạt mức giá tối thiểu!"
      ]
    };
  },
  computed: {
    price: {
      get() {
        if (this.value !== null && this.value !== "") {
          if (this.negative) {
            return `-${this.formatCurrency(this.value)}`;
          } else {
            return this.formatCurrency(this.value);
          }
        } else {
          return this.emptyValue;
        }
      },
      set(val) {
        this.$emit("change", this.convertToNumber(val));
        this.$emit("input", this.convertToNumber(val));
      }
    }
  },
  methods: {
    cleanNumber: function(value) {
      let result = "";

      if (value) {
        let flag = false;
        let arrayValue = value.toString().split("");

        for (let i = 0; i < arrayValue.length; i++) {
          if (this.isInteger(arrayValue[i])) {
            if (!flag) {
              // Remove leading zeros
              if (arrayValue[i] !== "0") {
                result = result + arrayValue[i];
                flag = true;
              }
            } else {
              result = result + arrayValue[i];
            }
          }
        }
      }
      return result;
    },

    convertToNumber(val) {
      if (val) {
        const number = this.cleanNumber(val);

        if (number !== "") {
          return Number(number);
        } else {
          return this.emptyValue;
        }
      } else {
        return this.emptyValue;
      }
    },

    formatCurrency(val) {
      if (isNaN(val)) {
        return this.emptyValue;
      }

      return Number(val)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

      // return Number(val).toLocaleString("vi-VN");
    },

    keyPress($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      // if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },

    isInteger(value) {
      let result = false;
      if (Number.isInteger(parseInt(value))) {
        result = true;
      }
      return result;
    }
  }
};
</script>

<style lang="scss" scoped>
.price--text-right {
  ::v-deep input {
    text-align: right;
  }
}
</style>
